<template>
    <div class="container">
        <loading-state
                v-if="loading">
        </loading-state>

        <h3>Repair Address</h3>
        <dl>
            <dt>Repair location</dt>
            <dd v-if="customerName">
                {{customerName}},
                <template v-if="address">
                    {{address.line_1}},
                    <span v-if="address.line_2">{{address.line_2}},</span>
                    {{address.town}},
                    <span class="text-uppercase">{{address.postcode}}</span>
                </template>
            </dd>
        </dl>
        <dl>
            <dt>Customer's phone number</dt>
            <dd>{{phoneNumber}}</dd>
        </dl>


        <button class="btn btn-info btn-sm" @click="$bvModal.show('change-address-modal')">Change Address</button>

        <change-address-modal></change-address-modal>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': true}"
                :nextLabel="`Next`"
                :nextRoute="`revisit.repair-date`"
                :prevRoute="`revisit.repair-parts`">
        </fixed-footer>
    </div>
</template>

<script>
    export default {
        name: "UpdateAddress",

        data() {
            return {
                address: null,
                customerName: null,
                loading: true,
                phoneNumber: null
            }
        },

        mounted() {
            this.loadAddress();

            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                if (modalId === 'change-address-modal') {
                    this.loadAddress();
                }
            })
        },

        methods: {
            loadAddress() {
                this.loading = true;
                this.$http.get(`/api/v4/warranty/booking/${localStorage.revisitBookingId}`)
                    .then(res => {
                        this.loading = false;
                        this.address = res.data.data.address;
                        this.customerName = res.data.data.order.full_name;
                        this.phoneNumber = res.data.data.phone_number;
                    })
            }
        }
    }
</script>

<style scoped>

</style>